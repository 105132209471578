<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline">
				<el-form-item label="人员姓名">
					<el-input v-model="formInline.name" clearable placeholder="请输入人员姓名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
					<el-button type="success" v-if="tableData.length>0" :loading="loading" @click="daochu"><i
							class="el-icon-download"></i>excel导出</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" stripe>
			<el-table-column prop="name" label="姓名"></el-table-column>
			<el-table-column prop="feedback_time" label="反馈时间"></el-table-column>
			<el-table-column prop="feedback_desc" label="反馈信息"></el-table-column>
			<el-table-column label="反馈照片">
				<template slot-scope="scope">
					<!--          <div class="imgbox_table" v-for="(item, index) in scope.row.feedback_picList" :key="index" :style="{ 'background-image' : `url(${item})`}"></div>-->
					<div>
						<el-image style="width: 100px; height: 100px" :src="scope.row.feedback_picList[0]"
							:preview-src-list="scope.row.feedback_picList">
						</el-image>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="points" label="审核状态">
				<template slot-scope="scope">
					<span>{{ checkStatusLabel(scope.row.audit_time) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="points" label="奖励积分"></el-table-column>
			<el-table-column prop="audit_time" label="审核时间"></el-table-column>
			<el-table-column prop="audit_opinion" label="审核意见"></el-table-column>
			<el-table-column label="操作" fixed="right" width="200">
				<template slot-scope="scope">
					<el-button @click="auditFeedbackFn(scope.row)" type="primary" size="mini"
						:disabled="Boolean(scope.row.audit_time)">审核</el-button>
					<!--          <el-button @click="submitFormAll(scope.row)" type="primary" size="mini" :disabled="Boolean(scope.row.is_gained)">发放积分</el-button>-->
				</template>
			</el-table-column>
		</el-table>
		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size.sync="fenye.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		<!-- 反馈审核 -->
		<el-dialog append-to-body :close-on-click-modal="false" :visible.sync="auditFeedbackDialog" title="审核"
			width="700px" :before-close="auditFeedbackhandleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="100px"
				class="demo-ruleForm">


				<el-form-item label="审核结果" prop="isPass">
					<el-select v-model="ruleForm.isPass" clearable placeholder="请选择审核结果">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="审核意见" prop="auditOpinion">
					<el-input type="textarea" v-model="ruleForm.auditOpinion" placeholder="请输入审核意见"></el-input>
				</el-form-item>

				<el-form-item v-if="ruleForm.isPass == 1"
					style="text-align: right; padding-right: 10px;  color: #409EFF;"><span
						style="color:red; margin-right: 5px;">*</span>1消费积分=10荣誉积分</el-form-item>
				<el-form-item v-if="ruleForm.isPass == 1" label="奖励积分" prop="points">
					<!-- <el-input v-model="ruleForm.points" placeholder="请输入奖励积分倍数"></el-input> -->
					<el-autocomplete class="inline-input" v-model="ruleForm.points" :fetch-suggestions="querySearch"
						@select="pointChange" @change="pointChange" placeholder="请输入奖励积分倍数"></el-autocomplete>
					<span style="color: #b1b0b0"> * {{point}} 积分 / {{unit}}</span>
				</el-form-item>
				<el-form-item v-if="rewardPoints" style="color: #F56C6C;">您此次奖励的积分是{{ rewardPoints }}</el-form-item>

				<el-form-item>
					<el-button type="primary" v-if="ruleForm.isPass == 1" @click="submitForm('ruleForm')"
						:disabled="tijiao">提交</el-button>
					<el-button type="primary" v-else @click="reject('ruleForm')" :disabled="tijiao">提交</el-button>
					<!-- <el-button type="primary" @click="submitFormAll" :disabled="tijiao">审核全部</el-button> -->
					<!-- <el-button @click="cancelFn">取消</el-button> -->
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { Decimal } from "decimal.js";
	import {
		getFeedbackList,
		actVolFeedbackAudit,
		actVolFeedbackAuditAll,
		getVolServiceTypeById,
		exportList,
		findAuditRecords
	} from '@/api/showLove'
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	export default {
		props: {
			actuuid: {
				type: String
			},
			status: {
				type: Number
			},
			pricePoint: {
				type: Number
			},
			serviceType: {
				type: Number
			},
			pointType: {
				type: Number
			}
		},
		data() {
			const checkPoints = (rule, value, callback) => {
				if (value < 0 || value > this.pricePoint) {
					callback(new Error('积分必须不超过' + this.pricePoint + '积分'))
				} else {
					callback()
				}
			}
			return {
				loading: false,
				options: [{
					value: '1',
					label: '通过'
				}, {
					value: '0',
					label: '不通过'
				}],
				formInline: {
					name: '',
				},
				tableData: [],
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10,
				},
				auditFeedbackDialog: false,
				volName: '',
				uuid: '',
				ruleForm: {
					isPass: '1',
					isAddPoint: null,
					points: null,
					isAdd: 1,
					auditOpinion: ''
				},
				rules: {
					points: [{
							required: true,
							message: '请输入奖励积分倍数',
							trigger: 'change'
						},
						// { validator: checkPoints, trigger: ['blur', 'change'] }
					],
					auditOpinion: [{
						required: true,
						message: '请填写审核意见',
						trigger: 'change'
					}, ],
					isPass: [{
						required: true,
						message: '请选择审核结果',
						trigger: 'change'
					}, ],
				},
				tijiao: false,
				voluuid: '',
				restaurants: [{
					value: "1"
				}, {
					value: "2"
				}, {
					value: "3"
				}, {
					value: "4"
				}, {
					value: "5"
				}, {
					value: "6"
				}, {
					value: "7"
				}, {
					value: "8"
				}, {
					value: "9"
				}, {
					value: "10"
				}, ],
				point: '',
				unit: '',
				rewardPoints: '',
				tableDataExport: [],
				num:'',
			};
		},

		created() {
			this.getList();
			getVolServiceTypeById({
				id: this.serviceType
			}).then(res => {
				if (res.result == 200) {
					this.unit = res.detail.unit;
					this.point = res.detail.point;
				}
			})
		},

		computed: {},

		mounted() {},

		methods: {
			daochu() {
				let that = this;
				that.loading = true;
				exportList({
					orderRule: 'person',
					actuuid: that.actuuid,
					name: that.formInline.name
				}).then(res => {
					that.loading = false;
					const data = res;
					const filename = '反馈列表.xls'
					const url = window.URL.createObjectURL(new Blob([data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', filename)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				})
			},
			pointChange(e) {
				let point = (e?.constructor === Object) ? e.value : e;
				//js数学计算库：decimal.js，解决js计算精度问题
				let integral = Number(new Decimal(point).mul(new Decimal(this.point)));
				if (this.pointType == 2) {
					this.rewardPoints = integral + '消费积分'
				} else if (this.pointType == 1) {
					this.rewardPoints = integral * 10 + '荣誉积分' //1消费积分=10荣誉积分
				} else if (this.pointType == 0) {
					this.rewardPoints = integral + '消费积分，可由社员自主分配'
				}
			},
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			getList() {
				getFeedbackList({
					pageSize: this.fenye.pagesize,
					pageNum: this.currentPage,
					orderRule: 'person',
					actuuid: this.actuuid,
					name: this.formInline.name
				}).then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						this.tableData = detail.list;
						detail.list.forEach((item, index) => {
							this.tableData[index].feedback_picList = item.feedback_pics.split(",");
						})
						this.fenye.totalnum = detail.totalRow;
					}
				})
			},
			checkStatusLabel(val) {
				if (val) {
					return '已审核'
				} else {
					return '未审核'
				}
			},
			querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
					return (restaurant.value.indexOf(queryString) === 0);
				};
			},
			//打开审核报名弹框
			auditFeedbackFn(row) {
				console.log("row:", row);
				this.uuid = row.uuid;
				this.voluuid = row.vol_uuid;
				this.volName = row.nickname;
				this.auditFeedbackDialog = true;
			},
			//取消添加
			cancelFn(type) {
				this.$refs.ruleForm.resetFields();
				this.auditFeedbackDialog = false;
				this.rewardPoints = 0;
				if (type == 'add') {
					this.getList();
				}
			},
			//重置表单
			resetForm() {
				this.$refs.ruleForm.resetFields();
			},
			//关闭审核积分弹框
			auditFeedbackhandleClose(done) {
				this.resetForm();
				done()
				this.rewardPoints = 0;
			},
			//审核不通过
			reject(formName) {
				let that = this;
				console.log("this.status:", this.status);
				if (this.status == 0) {
					this.ruleForm.isAddPoint = 1;
				} else {
					this.ruleForm.isAddPoint = 0;
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否审核不通过该社员反馈?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							that.tijiao = true;
							let points = 0;
							actVolFeedbackAudit({
								uuid: that.uuid,
								isAddPoint: that.ruleForm.isAddPoint,
								points: points,
								auditOpinion: '不通过：' + that.ruleForm.auditOpinion,
								isAdd: 1,
								auditStatus: 2
							}).then(res => {
								that.tijiao = false;
								if (res.result == 200) {
									this.$message.success('审核成功');
									this.cancelFn('add');
								} else {
									this.$message.error(res.description)
								}
							})
						}).catch(() => {

						});
					}
				})
			},
			// 审核通过
			submitForm(formName) {
				let that = this;
				console.log("this.status:", this.status);
				if (this.status == 0) {
					this.ruleForm.isAddPoint = 1;
				} else {
					this.ruleForm.isAddPoint = 0;
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						findAuditRecords({
							actuuid: that.actuuid,
							voluuid: that.voluuid,
						}).then(res => {
							this.$confirm('该社员已有'+res.detail+'次审核通过反馈信息，是否确认审核该社员反馈信息?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								that.tijiao = true;
								// let points = that.ruleForm.points * that.point;
								// js数学计算库：decimal.js，解决js计算精度问题
								let points = Number(new Decimal(that.ruleForm.points).mul(new Decimal(that.point)));
								actVolFeedbackAudit({
									uuid: that.uuid,
									isAddPoint: that.ruleForm.isAddPoint,
									points: points,
									auditOpinion: '通过：' + that.ruleForm.auditOpinion,
									isAdd: 1,
									auditStatus: 1
								}).then(res => {
									that.tijiao = false;
									if (res.result == 200) {
										this.$message.success('审核成功');
										this.cancelFn('add');
									} else {
										this.$message.error(res.description)
									}
								})
							}).catch(() => {
							
							});
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//确认审核全部
			submitFormAll(row) {
				this.$confirm('当前可能存在未审核的数据，执行该操作会将所有未审核的数据进行审核通过并按照满额积分发放，请确认执行？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.tijiao = true;
					actVolFeedbackAuditAll({
						actuuid: this.actuuid,
						voluuid: row.vol_uuid,
						isAdd: 1,
					}).then(res => {
						this.tijiao = false;
						if (res.result == 200) {
							row.is_gained = true;
							row.audit_time = new Date().toLocaleString();
							this.$message.success('审核成功');
						} else {
							this.$message.error(res.description)
						}
					})
				}).catch(() => {
					console.log('取消审核全部')
				});
			},
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>
<style lang='scss' scoped>
	.imgbox_table {
		height: 80px;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;
	}

	.paginationbox {
		text-align: right;
		margin-top: 20px;
	}

	.demo-ruleForm {
		::v-deep .el-input__inner {
			border-radius: 4px;
		}

		::v-deep .el-input-group__append {
			background: #fff;
			border: none;
			padding: 0 10px;
		}
	}
</style>