<template>
  <div class="wrapper">
    <el-dialog :title="operationType === 'view'?'查看志愿服务':'修改志愿服务'" :visible.sync="dialogFormVisible" width="1000px" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm" :disabled="operationType === 'view'">
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="项目标题" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入项目标题"></el-input>
        </el-form-item>
        <el-form-item label="项目类型" prop="serviceCategory">
          <el-select v-model="ruleForm.serviceCategory" :disabled="operationType !== 'view'" placeholder="请选择项目类型">
            <el-option label="一次性服务" :value="0"></el-option>
            <el-option label="周期性服务" :value="1"></el-option>
            <el-option label="按次服务" :value="2"></el-option>
            <el-option label="按量服务" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否需签到" prop="isSign">
          <el-radio-group v-model="ruleForm.isSign">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="0">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否需定位" prop="isPosition">
          <el-radio-group v-model="ruleForm.isPosition">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="0">不需要</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item label="是否有服务上限" prop="limitFlag" v-if="ruleForm.serviceCategory === 2 || ruleForm.serviceCategory === 3">
           <el-radio-group v-model="ruleForm.limitFlag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="服务上限" prop="limitFeedback" v-if="(ruleForm.serviceCategory === 2 || ruleForm.serviceCategory === 3) ">
          <el-input v-model="ruleForm.limitFeedback" placeholder="请输入服务上限"></el-input>
        </el-form-item>

        <el-form-item label="单次反馈数量上限" prop="limitSingle" v-if="ruleForm.serviceCategory === 3">
          <el-input v-model="ruleForm.limitSingle"  oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')" placeholder="请输入单次反馈数量上限"></el-input>
        </el-form-item>

        <el-form-item label="招募时间" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label="任务时间" prop="date">
          <el-row v-for="oneTime in showDates" :key="oneTime.uuid" style="margin-bottom:10px">
            <el-col :span="6">
              <el-date-picker
                disabled
                style="width: 90%"
                v-model="oneTime.enrollment"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="任务时间">
              </el-date-picker>
            </el-col>
            <el-col :span="7" v-for="(durTime,index) in oneTime.durationTime" :key="index">
              <el-time-picker
                is-range
                disabled
                style="width: 90%"
                v-model="durTime.value"
                range-separator="至"
                value-format="HH:mm:ss"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker>
            </el-col>
          </el-row>
        </el-form-item>





        <el-form-item label="服务地址" prop="address">
          <el-input v-model="ruleForm.address" placeholder="请选择服务地址">
            <el-button slot="append" icon="el-icon-location-outline" @click="openMapFn"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="服务详情" prop="content">
          <vue-editor v-model="ruleForm.content"  id="container" @image-added="handleImageAdded" useCustomImageHandler> </vue-editor>
        </el-form-item>
        <el-form-item label="招募人数" prop="needCount">
          <el-input v-model="ruleForm.needCount"  oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')" placeholder="请输入招募人数"></el-input>
        </el-form-item>
        <!-- <el-form-item label="活动可获取积分" prop="pricePoint">
          <el-input v-model="ruleForm.pricePoint"  oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')" placeholder="请输入活动可获取积分"></el-input>
        </el-form-item> -->

        <el-form-item label="活动规则" prop="rule">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入活动规则"
            v-model="ruleForm.rule">
          </el-input>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="活动图片" prop="picUrl">
              <el-upload
                  action="#"
                  :class="{shopfacehide: shopfacehideUploadEidt}"
                  :auto-upload="false"
                  accept="image/*"
                  :show-file-list="true"
                  list-type="picture-card"
                  :on-change="shopfaceImgchange"
                  :file-list="shopfaceList"
                >
                  <div slot="file" slot-scope="{file}">
                    <img :src="file.url"  style="width:100%;height:146px !important">
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="bigImg(file)"><i class="el-icon-zoom-in"></i></span>
                        <span class="el-upload-list__item-delete" @click="shopfaceImgRemove(file)" v-if="operationType === 'edit'">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                  </div>
                  <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="列表图片" prop="listPicUrl">
              <el-upload
                  action="#"
                  :class="{yyzzhide: yyzzhideUploadEidt}"
                  :auto-upload="false"
                  accept="image/*"
                  :show-file-list="true"
                  list-type="picture-card"
                  :on-change="yingyezhizhaoImgchange"
                  :file-list="yingyezhizhaoList"
                >
                  <div slot="file" slot-scope="{file}">
                    <img :src="file.url"  style="width:100%;height:146px !important">
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="bigImg(file)"><i class="el-icon-zoom-in"></i></span>
                        <span class="el-upload-list__item-delete" @click="yingyezhizhaoImgRemove(file)" v-if="operationType === 'edit'">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                  </div>
                  <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        
        
        <!-- <el-form-item label="志愿项目" prop="serviceTypes">
          <el-select v-model="ruleForm.serviceTypes" clearable placeholder="请选择服务类型">
            <el-option v-for="item in serviceTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <div style="display: flex;">
          <el-form-item label="志愿项目" prop="serviceTypes">
            <el-select v-model="ruleForm.serviceTypes" clearable placeholder="请选择服务类型" @change="changeServiceTypes">
              <el-option v-for="item in serviceTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动可获取积分" prop="pricePoint">
            <el-input v-model="ruleForm.pricePoint" readonly></el-input>
          </el-form-item>
        </div>
        <el-form-item label="积分发放类型" prop="pointType">
          <el-select v-model="ruleForm.pointType" placeholder="请选择项目类型">
            <el-option label="自主分配" :value="0"></el-option>
            <el-option label="荣誉积分" :value="1"></el-option>
            <el-option label="消费积分" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招募范围" prop="communityCode" v-if="operationType === 'edit'">
          <el-select
            v-model="ruleForm.communityCode"
            multiple
            placeholder="请选择招募范围">
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="招募范围" prop="communityCode" v-if="operationType === 'view'">
          <el-input v-model="ruleForm.community_name" placeholder="请输入招募范围"></el-input>
        </el-form-item>
        
        <!-- <el-form-item label="是否上架" prop="isOnSale">
          <el-radio-group v-model="ruleForm.isOnSale">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <el-form-item label="是否置顶" prop="stickFlag">
          <el-radio-group v-model="ruleForm.stickFlag">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="排序">
          <el-input v-model="ruleForm.order"  oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')" placeholder="请输入排序"></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="personname">
          <el-input v-model="ruleForm.personname" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="咨询电话" prop="hotline">
          <el-input v-model="ruleForm.hotline" oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item v-if="operationType === 'edit'">
          <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <event-map ref="eventMap" contentId="showlove_update" @getEventMapData="getEventMapData"></event-map>

    <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { upLoadFileObj } from '@/api/itemCategory'
import { VueEditor } from 'vue2-editor'
import eventMap from './eventmap.vue'
import {  detailAct, updateAct } from '@/api/showLove'
import { getDictItem, getVolServiceType } from '@/api/showLove'
import { communityOfUser } from '@/api/system';
import { sureMessageBox } from '@/utils/common'
export default {
  name:"",
  components:{
    eventMap,
    VueEditor
  },
  props:{
    activityEditType: {
      type: String,
      default: 'add'
    },
    updateActuuid: {
      type: String,
      default: ''
    },
    operationType: {
      type: String,
      default: 'edit'
    }
  },
  data(){
    return {
      imgDialogVisible: false,
      dialogImageUrl: '',
      dialogFormVisible: false,
      communitys:[],
      serviceTypeOptions: [],
      ruleForm: {
        name: '', 
        title: '',
        serviceCategory: null,
        isSign: 0,
        isPosition: 0,
        limitFeedback: null,
        limitFlag: null,
        limitSingle:'',
        address: '',
        content: '',
        needCount: '',
        serviceTypes: '',
        communityCode: [],
        personname: '',
        order: '',
        hotline: '',
        pricePoint: '',
        rule:'',
        picUrl: '',
        listPicUrl: '',
        date: [],
        coordinate: '',
        days: '',
        isOnSale: 2,
        stickFlag: 0,
        dates: [],
        community_name: '',
        pointType: 0
      },
      showDates: [],
      shopfaceList: [],
      shopfacehideUploadEidt: false,
      yingyezhizhaoList: [],
      submitLoading: false,
      yyzzhideUploadEidt: false,
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入活动标题', trigger: 'blur' },
        ],
        serviceCategory: [
          { required: true, message: '请选择项目类型', trigger: 'change' }
        ],
        isSign: [
          { required: true, message: '请选择是否需签到', trigger: 'change' }
        ],
        isPosition: [
          { required: true, message: '请选择是否需定位', trigger: 'change' }
        ],
        date: [
          { required: true, message: '请选择活动时间', trigger: 'change' }
        ],
        days: [
          { required: true, message: '请选择任务时间', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请选择服务地址', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入服务详情', trigger: 'blur' }
        ],
        needCount: [
          { required: true, message: '请输入招募人数', trigger: 'blur' },
          { validator: this.checkLimitNum, trigger: 'change' }
        ],
        serviceTypes: [
          { required: true, message: '请选择志愿项目', trigger: 'change' }
        ],
        pointType: [
          { required: true, message: '请选择获取积分类型', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ],
        communityCode: [
          { required: true, message: '请选择招募范围', trigger: 'change' }
        ],
        // personname: [
        //   { required: true, message: '请输入联系人', trigger: 'blur' }
        // ],
        // hotline: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { min: 11, max: 11, message: '请输入正确的手机号格式', trigger: 'blur' }
        // ],
        picUrl: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        listPicUrl: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        limitFeedback: [
          { required: true, message: '请输入服务上限', trigger: 'blur' },
          { validator: this.checkLimitNum, trigger: 'change' }
        ],
        // limitFlag: [
        //   { required: true, message: '请选择是否有服务上限', trigger: 'change' }
        // ],
        limitSingle: [
          { required: true, message: '请输入单次反馈数量上限', trigger: 'blur' },
          { validator: this.checkLimitNum, trigger: 'change' }
        ],
      },
      curPoint: '',
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getServiceTypes();
    this.getCommunityOfUser();
  },
  computed:{},
  methods:{
    changeServiceTypes(value) {
      let that = this;
      this.serviceTypeOptions.map(item => {
        if(item.id == value) {
          that.ruleForm.pricePoint = item.point + '/' + item.unit;
          that.curPoint = item.point;
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sureMessageBox('是否确认提交修改？').then(res=>{
            this.submitLoading = true;
            let data = {
              uuid: this.updateActuuid,
              userid: this.GLOBAL.adminId(),
              name: this.ruleForm.name, 
              title: this.ruleForm.title,
              serviceCategory: this.ruleForm.serviceCategory,
              isSign: this.ruleForm.isSign,
              isPosition: this.ruleForm.isPosition,
              limitFeedback: this.ruleForm.limitFeedback,
              limitSingle: this.ruleForm.limitSingle,
              picUrl: this.ruleForm.picUrl,
              listPicUrl: this.ruleForm.listPicUrl,
              address: this.ruleForm.address,
              content: this.ruleForm.content,
              needCount: this.ruleForm.needCount,
              rule: this.ruleForm.rule,
              serviceTypes: this.ruleForm.serviceTypes,
              pointType: this.ruleForm.pointType,
              communityCode: this.ruleForm.communityCode.join(','),
              personname: this.ruleForm.personname,
              order: this.ruleForm.order,
              hotline: this.ruleForm.hotline,
              pricePoint: this.curPoint,
              beginTime: this.ruleForm.date[0],
              endTime: this.ruleForm.date[1],
              coordinate: this.ruleForm.coordinate,
              // dates: JSON.stringify(this.ruleForm.dates)
            }
            updateAct(data).then(res=>{
              this.submitLoading = false;
              if(res.result == 200){
                this.$message.success('修改成功');
                this.resetForm('ruleForm');
                this.shopfaceList= [];
                this.shopfacehideUploadEidt = false;
                this.yingyezhizhaoList = [];
                this.yyzzhideUploadEidt = false;
                this.dialogFormVisible = false;
                this.$emit('updateRefreshList')
                
              }else{
                this.$message.warning(res.description)
              }
            }).catch(err=>{
              this.submitLoading = false;
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //图片预览或附件下载
    bigImg(file) {
      let url = file.url;
      this.dialogImageUrl = url;
      this.imgDialogVisible = true;
    },
    //活动图片
    shopfaceImgchange(file, fileList) { 
      this.shopfacehideUploadEidt = fileList.length>0;
			let formData = new FormData()
  		formData.append('file', file.raw);
			upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
          contentType: 'image/*'
			}).then((res)=>{
				if(res.result==200){
					this.$message.success('图片上传成功')
          let url = res.detail[0].path;  
          this.shopfaceList.push({name:res.detail[0].id, url})
          this.shopfacehideUploadEidt = true;
          this.ruleForm.picUrl = url;
				}else{
					this.$message.error('图片上传失败')
				}
			})
  		}, 
    //活动图片删除
    shopfaceImgRemove(file) {
			this.shopfaceList.map((item,index)=>{
				if(item.name==file.name){
					this.shopfaceList.splice(index,1)
				}
			})
      this.ruleForm.picUrl = '';
      this.shopfacehideUploadEidt = this.shopfaceList.length>0
    }, 
    //列表图片
    yingyezhizhaoImgchange(file, fileList) { 
      this.yyzzhideUploadEidt = fileList.length>0;
			let formData = new FormData()
  		formData.append('file', file.raw);
			upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
          contentType: 'image/*'
			}).then((res)=>{
				if(res.result==200){
					this.$message.success('图片上传成功')
          let url = res.detail[0].path;  
          this.yingyezhizhaoList.push({name:res.detail[0].id, url})
          this.yyzzhideUploadEidt = true;
          this.ruleForm.listPicUrl = url;
				}else{
					this.$message.error('图片上传失败')
				}
			})
  	}, 
    //列表图片删除
    yingyezhizhaoImgRemove(file) {
			this.yingyezhizhaoList.map((item,index)=>{
				if(item.name==file.name){
					this.yingyezhizhaoList.splice(index,1)
				}
			})
      this.ruleForm.listPicUrl = ''
      this.yyzzhideUploadEidt = this.yingyezhizhaoList.length>0
    },
    //富文本上传图片
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);

      //上传图片
      upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId()
			}).then(res => {
        if (res.result == 200) {
          let url = res.detail[0].path
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //打开地图选地址
    openMapFn(){
      this.$refs.eventMap.dialogMapVisible = true;
    },
    //获取地址和经纬度
    getEventMapData(data) {
      console.log(data)
      this.ruleForm.address = data[2];
      this.ruleForm.coordinate = data[0] + ',' + data[1];
    },
    //获取服务类型
    getServiceTypes(){
      // getDictItem({
      //   type: 'vol_serveice_type'
      // }).then(res=>{
      //   this.serviceTypeOptions = res.detail;
      // })
      getVolServiceType().then(res => {
        this.serviceTypeOptions = res.detail.list;
      })
    },
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //数组对象去重
    fn3(tempArr) {
      let result = [];
      let obj = {};
      for (let i = 0; i < tempArr.length; i++) {
        if (!obj[tempArr[i].enrollment]) {
          result.push(tempArr[i]);
          obj[tempArr[i].enrollment] = true;
        }
      }
      return result;
    },
    //获取活动主体详情
    getActDetail(){
      detailAct({
        userid: this.GLOBAL.adminId(),
        uuid: this.updateActuuid,	
      }).then(res=>{
        if(res.result == 200){
          let detail = res.detail;
          /* detail.dates.forEach(item=>{
            item.duration_time = [item.duration_start, item.duration_end]
          }) */

          
          //用来展示服务时间
          let newDates = [...detail.dates];
            let nosameDates = this.fn3(newDates);
            nosameDates.forEach( item => item.durationTime = [])
            nosameDates.forEach(item=>{
              newDates.forEach(i=>{
                if(item.enrollment === i.enrollment){
                  if(i.duration_start && i.duration_end){
                    item.durationTime.push({
                      value: [i.duration_start, i.duration_end]
                    })
                  }
                  
                }
              })
            })
            this.showDates = [...nosameDates];
          var pricePoint = '';
          this.serviceTypeOptions.map(item => {
            if(item.id == detail.service_types) {
              pricePoint = item.point + '/' + item.unit;
              this.curPoint = item.point;
            }
          })
          this.ruleForm = {
            name: detail.name, 
            title: detail.title,
            serviceCategory: detail.service_category,
            isSign: detail.is_sign,
            isPosition: detail.is_position,
            limitFeedback: detail.limit_feedback,
            limitSingle: detail.limit_single,
            address: detail.address,
            content: detail.content,
            needCount: detail.need_count,
            serviceTypes: detail.service_types,
            pointType: detail.point_type,
            communityCode: detail.community_code.split(','),
            personname: detail.personname,
            order: detail.order,
            hotline: detail.hotline,
            pricePoint: pricePoint,
            rule: detail.rule,
            picUrl: detail.pic_url,
            listPicUrl: detail.list_pic_url,
            coordinate: detail.coordinate,
            days: detail.days,
            isOnSale: detail.is_on_sale,
            stickFlag: detail.stick_flag,
            date: [detail.begin_time, detail.end_time],
            community_name: detail.community_name,
            // dates: detail.dates
          };
          detail.limit_feedback ? this.ruleForm.limitFlag = true : this.ruleForm.limitFlag = false;
          this.shopfaceList =  [{
            name: '图片',
            url: detail.pic_url
          }];
          this.shopfacehideUploadEidt = true;
          this.yingyezhizhaoList =  [{
            name: '图片',
            url: detail.list_pic_url
          }];
          this.yyzzhideUploadEidt = true;

        }else{
          this.$message.warning(res.description)
        }
      })
    },
    checkLimitNum(rule, value, callback) {
      var regNumber = /^\+?[1-9][0-9]*$/;
      if (regNumber.test(value) == false) {
        return callback(new Error('请输入大于0的整数'));
      }else{
        callback();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .bigicon{
    font-size: 24px;
    color: #999;
    opacity: 0.6;
    .el-icon-remove-outline{
      color: #F56C6C;
    }
    .el-icon-circle-plus-outline{
      color: #67C23A;
    }
    i{
      cursor: pointer;
    }
  }
  // 隐藏上传按钮
  ::v-deep {
    .yyzzhide,
    .shopfacehide,
    .hualanghide{
      &>div.el-upload--picture-card {
        display: none;
      }
    }
    
  }
</style>