<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="活动名称">
					<el-input v-model="formInline.name" clearable placeholder="请输入活动名称"></el-input>
				</el-form-item>
				<el-form-item label="招募时间">
					<el-date-picker v-model="formInline.time" clearable type="daterange" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="所属社区" prop="communityCode">
					<el-select clearable v-model="formInline.communityCode" placeholder="请选择社区">
						<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="活动状态" v-if="listType !== 'check'">
					<el-select v-model="formInline.status" clearable placeholder="请选择活动类型">
						<el-option v-for="item in activityStatus" :key="item.id" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="志愿项目" prop="serviceTypes">
				  <el-select v-model="formInline.serviceTypes" clearable placeholder="请选择服务类型">
				    <el-option v-for="item in serviceTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
					<el-button v-if="listType !== 'check'" type="primary" @click="addRow"
						icon="el-icon-plus">新增</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" style="width: 100%">
			<el-table-column label="序号" type="index" :index="indexMethod" align="center">
			</el-table-column>
			
			<el-table-column label="活动名称" prop="name" align="center">
			</el-table-column>
			<el-table-column label="活动图片" align="center">
				<template slot-scope="scope">
					<div class="imgbox_table" :style="{ 'background-image' : `url(${scope.row.pic_url})`}"></div>
				</template>
			</el-table-column>
			<el-table-column label="发布时间" prop="create_time" align="center">
			</el-table-column>
			<el-table-column label="开始时间" prop="begin_time" align="center">
			</el-table-column>
			<el-table-column label="结束时间" prop="end_time" align="center">
			</el-table-column>
			<el-table-column label="志愿项目" prop="servicenames" align="center">
			</el-table-column>
			<el-table-column label="任务二维码" prop="is_on_sale"  width="100px"  align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_on_sale == 1" style="cursor: pointer;"><img @click="openQrcode(scope.row)" style="width: 20px; height: 20px;" src="../../../assets/imgs/qrcode.png" alt=""></span>
          <span v-else>/</span>
        </template>
      </el-table-column>
			<el-table-column label="招募人数" width="70" align="center" prop="need_count">
			</el-table-column>
			<el-table-column label="报名人数" width="70" align="center" prop="apply_count">
			</el-table-column>
			<el-table-column label="报名待审核" width="80" align="center">
				<template slot-scope="scope">
					<div :style=" { color: scope.row.apply_not_audit > 0 ? 'red' : 'unset' }">
						{{scope.row.apply_not_audit}}
					</div>
				</template>
			</el-table-column>
			<el-table-column label="已招人数" width="70" align="center" prop="pass_count">
			</el-table-column>
			<el-table-column label="党员参与人数" width="70" align="center" prop="politicals">
			</el-table-column>
			<el-table-column label="特殊困难群体参与人数" width="70" align="center" prop="healths">
			</el-table-column>
			<el-table-column label="反馈待审核" width="80" align="center">
				<template slot-scope="scope">
					<div :style="{color: scope.row.feedback_not_audit > 0 ? 'red' : 'unset'}">
						{{scope.row.feedback_not_audit}}
					</div>
				</template>
			</el-table-column>
			<el-table-column label="上架状态" align="center">
				<template slot-scope="scope">
					<span>{{ backText(scope.row.is_on_sale, isOnSaleOptions) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="置顶状态" align="center">
				<template slot-scope="scope">
					<span>{{ backText(scope.row.stick_flag, stickFlagOptions) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="审核状态" align="center">
				<template slot-scope="scope">
					<span>{{ backText(scope.row.check_flag, checkStatus) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="审核意见" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.check_msg">{{ scope.row.check_msg }}</span>
					<span v-else>/</span>
				</template>
			</el-table-column>
			<el-table-column label="活动状态" align="center">
				<template slot-scope="scope">
					<span style="color:#383838;" v-if="scope.row.status === 0">招募中</span>
					<span style="color:#ff5733;" v-else-if="scope.row.status === 1">名额已满</span>
					<span style="color:#13ce66;" v-else-if="scope.row.status === 2">招募结束</span>
					<span style="color:#808080;" v-else-if="scope.row.status === 3">进行中</span>
					<span style="color:#808080;" v-else-if="scope.row.status === 4">已结束</span>
					<span style="color:#333;" v-else>待招募</span>
				</template>
			</el-table-column>
			<el-table-column v-if="listType === 'check'" fixed="right" label="操作" width="160" align="center">
				<template slot-scope="scope">
					<el-button @click="viewRow(scope.row)" size="mini">查看</el-button>
					<el-button @click="checkActivity(scope.row)" type="primary" size="mini">审核</el-button>
				</template>
			</el-table-column>


			<!--
        1、管理员可以做一切操作
        2、登录账号的Levelcode和创建志愿服务的Levelcode相同时（scope.row.levelcode === userLevelcode ）可做一切操作，否则只能查看
      -->

			<el-table-column v-else fixed="right" label="操作" width="280" align="center">
				<template slot-scope="scope">
					<el-button @click="viewRow(scope.row)" size="mini">查看</el-button>
					<el-button
						v-if="(scope.row.status === 0 || scope.row.status === 1) && (scope.row.levelcode === userLevelcode || userLevelcode === '-1')"
						@click="editActivity(scope.row)" :disabled="scope.row.is_on_sale === 1" type="primary"
						size="mini">修改</el-button>
					<el-button v-if="userLevelcode === '-1' || scope.row.levelcode === userLevelcode"
						@click="deleteRow(scope.row)" type="danger" size="mini"
						:disabled="scope.row.is_on_sale === 1">删除</el-button>

					<el-popover
						v-if="scope.row.status !== 4 && (scope.row.levelcode === userLevelcode || userLevelcode === '-1')"
						placement="left" style="margin-left: 10px;" trigger="click">
						<div class="btnsbox">
							<el-button v-if="scope.row.status === 0 || scope.row.status === 1"
								@click="stickRow(scope.row)" type="primary" size="mini">
								<span v-if="scope.row.stick_flag === 1">取消置顶</span>
								<span v-if="scope.row.stick_flag === 0  || !scope.row.stick_flag">置顶</span>
							</el-button>
							<el-button v-if="(scope.row.check_flag === '0' || !scope.row.check_flag)"
								@click="submitCheck(scope.row)" type="primary" size="mini">提交审核
							</el-button>
							<el-button @click="stopRow(scope.row)"
								v-if="scope.row.is_on_sale === 1 && scope.row.status === 0" type="primary"
								size="mini">结束招募
							</el-button>
							<el-button @click="startRow(scope.row)" v-if="scope.row.status === 2" type="primary"
								size="mini">启动招募
							</el-button>
							<el-button @click="startActivity(scope.row)" v-if="scope.row.status === 2" type="primary"
								size="mini">启动活动
							</el-button>
							<el-button @click="stopActivity(scope.row)" v-if="scope.row.status === 3" type="primary"
								size="mini">停止活动
							</el-button>
							<el-button @click="upDownRow(scope.row)" v-if="scope.row.check_flag === '2'" type="primary"
								size="mini">
								<span v-if="scope.row.is_on_sale === 1">下架</span>
								<span v-if="scope.row.is_on_sale === 2">上架</span>
							</el-button>
							<el-button @click="lookVolList(scope.row)" type="primary" size="mini">报名列表</el-button>
							<el-button @click="lookFeedbackList(scope.row)" type="primary" size="mini">反馈列表</el-button>
						</div>
						<el-button type="success" size="mini" slot="reference">更多</el-button>
					</el-popover>
					<el-popover v-if="scope.row.status == 4" placement="left" style="margin-left: 10px;min-width: none;"
						trigger="click">
						<el-button @click="lookVolList(scope.row)" type="primary" size="mini">报名列表</el-button>
						<el-button @click="lookFeedbackList(scope.row)" type="primary" size="mini">反馈列表</el-button>
						<el-button type="success" size="mini" slot="reference">更多</el-button>
					</el-popover>
				</template>
			</el-table-column>
		</el-table>

		<div class="pagebox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
			</el-pagination>
		</div>

		<!-- 新增 -->
		<add-row ref="addRow" @updateRefreshList="updateRefreshList"></add-row>

		<!-- 修改 -->
		<update-row ref="updateRow" :operationType="operationType" :updateActuuid="updateActuuid"
			@updateRefreshList="updateRefreshList"></update-row>

		<!-- 报名列表 -->
		<el-dialog append-to-body title="报名列表" :close-on-click-modal='false' :visible.sync="volListFlag"
			v-if="volListFlag" width="70%">
			<vol-list :actuuid="updateActuuid" :status="currentActStatus"></vol-list>
		</el-dialog>
		<!-- 反馈列表 -->
		<el-dialog append-to-body title="反馈列表" :close-on-click-modal='false' :visible.sync="feedbackListFlag"
			v-if="feedbackListFlag" width="75%">
			<feedback-list :actuuid="updateActuuid" :serviceType="serviceType" :pointType="pointType"
				:status="currentServiceCategory" :pricePoint="pricePoint"></feedback-list>
		</el-dialog>
		<!-- 活动审核 -->
		<el-dialog append-to-body title="审核" :close-on-click-modal='false' :visible.sync="checkDialogVisible"
			width="500px" :before-close="checkhandleClose">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="审核结果" prop="ispass">
					<el-radio-group v-model="form.ispass">
						<el-radio label="1">通过</el-radio>
						<el-radio label="0">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审核意见" prop="opinion" v-if="form.ispass === '0'">
					<el-input type="textarea" v-model="form.opinion"></el-input>
				</el-form-item>
				<el-form-item label-width="10px" style="margin-top: 40px; text-align: center;">
					<el-button type="primary" @click="submitForm('form')">提交审核</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<!-- 二维码 -->
    <el-dialog
      :title="qrcodeTitle"
      :visible.sync="qrcodedialogVisible"
      width="400px">
      <div style="text-align: center;">
        <el-image 
          style="width: 300px; height: 300px;"
          :src="qrcodeUrl">
        </el-image>
      </div>
    </el-dialog>

	</div>
</template>

<script>
	import {
		queryPageAct,
		deleteAct,
		recruitAct,
		stopRecruitAct,
		stickAct,
		upDownAct,
		checkBeforeDeleteAct,
		auditAct,
		submitAct,
		volActStart,
		volActComplete,
		getVolServiceType,
		qrcodeProduct
	} from '@/api/showLove'
	import {
		sureMessageBox
	} from '@/utils/common'
	import addRow from './addRow.vue'
	import updateRow from './updateRow.vue'
	import volList from './volList.vue'
	import feedbackList from './feedbackList.vue'
	import {
		communityOfUser
	} from "@/api/system";
	export default {
		name: "",
		components: {
			addRow,
			updateRow,
			volList,
			feedbackList
		},
		props: {
			listType: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				userLevelcode: localStorage.getItem('level'),
				form: {
					ispass: '',
					opinion: ''
				},
				formInline: {
					name: '',
					time: '',
					status: '',
					serviceTypes:''
				},
				refundform: {
					isPass: '',
					opinion: ''
				},
				tableData: [],
				communitys: [],
				currentPage: 1,
				totalRow: 0,
				pageSize: 10,
				checkStatus: [{
					value: null,
					label: '未提交'
				}, {
					value: '0',
					label: '未提交'
				}, {
					value: '1',
					label: '待审核'
				}, {
					value: '2',
					label: '审核通过'
				}, {
					value: '3',
					label: '审核不通过'
				}],
				activityStatus: [{
					value: 0,
					label: '招募中'
				}, {
					value: 1,
					label: '名额已满'
				}, {
					value: 2,
					label: '招募结束'
				}, {
					value: 3,
					label: '进行中'
				}, {
					value: 4,
					label: '已结束'
				}],
				//置顶状态
				stickFlagOptions: [{
					value: 0,
					label: '正常'
				}, {
					value: 1,
					label: '置顶'
				}],
				//上架状态
				isOnSaleOptions: [{
					value: 1,
					label: '上架'
				}, {
					value: 2,
					label: '下架'
				}],
				updateActuuid: '',
				operationType: 'edit',
				rules: {
					ispass: [{
						required: true,
						message: '请选择审核结果',
						trigger: 'change'
					}],
					opinion: [{
						required: true,
						message: '请输入审核意见',
						trigger: 'change'
					}]
				},
				checkDialogVisible: false,
				checkActuuid: '',
				volListFlag: false,
				currentActStatus: null,
				pricePoint: null,
				feedbackListFlag: false,
				currentServiceCategory: null,
				serviceType: '',
				serviceTypeOptions: [],
				qrcodeUrl:'',
				qrcodedialogVisible: false,
				qrcodeTitle: ''
			}
		},
		watch: {},
		created() {},
		mounted() {
			// this.getList();
			this.getServiceTypes();
			this.getCommunityOfUser();
		},
		activated() {
			this.getList()
		},
		computed: {},
		methods: {
			//返回对应文本
			backText(val, options) {
				let item = options.filter(item => item.value === val);
				if (item.length === 0) {
					return '-'
				} else {
					return item[0].label;
				}
			},
			getServiceTypes(){
			  getVolServiceType().then(res => {
			    this.serviceTypeOptions = res.detail.list;
			  })
			},
			getCommunityOfUser() {
				communityOfUser({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.communitys = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取活动二维码
			openQrcode(row){
				this.qrcodeUrl = "";
				this.qrcodeTitle = row.name + ' 活动二维码';
				this.qrcodedialogVisible = true;
				qrcodeProduct({
					uuid: row.uuid
				}).then(res=>{
					if(res.result == 200){
						this.qrcodeUrl = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取性别
			getUserSex(id) {
				if (!id) {
					return false;
				}
				let sex;
				if (id.length == 15) {
					if (parseInt(id.charAt(14) / 2) * 2 != id.charAt(14)) {
						sex = "男";
					} else {
						sex = "女";
					}
				} else if (id.length == 18) {
					if (parseInt(id.charAt(16) / 2) * 2 != id.charAt(16)) {
						sex = "男";
					} else {
						sex = "女";
					}
				}
				return sex;
			},
			//关闭审核表单
			checkhandleClose(done) {
				this.resetForm('form')
				done();
			},
			//审核表单重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//审核按钮
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否确认提交审核?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							auditAct({
								userid: this.GLOBAL.adminId(),
								uuid: this.checkActuuid,
								ispass: this.form.ispass,
								opinion: this.form.opinion
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('操作成功');
									this.resetForm('form');
									this.checkDialogVisible = false;
									this.checkActuuid = '';
									this.getList();
								} else {
									this.$message.error(res.description);
								}
							})
						}).catch(() => {

						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//上架或下架
			upDownRow(row) {
				let tip = '';
				let activityflag = null;
				if (row.is_on_sale === 1) {
					tip = "是否确认下架？"
					activityflag = 2;
				} else if (row.is_on_sale === 2) {
					tip = '是否确认上架'
					activityflag = 1;
				}
				sureMessageBox(tip).then(res => {
					upDownAct({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid,
						activityflag
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//置顶或取消
			stickRow(row) {
				let tip = '';
				let stickflag = null;
				if (row.stick_flag === 1) {
					tip = "是否确认取消置顶？"
					stickflag = 0;
				} else {
					tip = '是否确认设为置顶？'
					stickflag = 1;
				}

				sureMessageBox(tip).then(res => {
					stickAct({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid,
						stickflag
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//获取志愿活动列表
			getList() {
				queryPageAct({
					name: this.formInline.name,
					begin: this.formInline.time ? this.formInline.time[0] : '',
					end: this.formInline.time ? this.formInline.time[1] : '',
					communityCode: this.formInline.communityCode,
					status: this.formInline.status,
					serviceTypes: this.formInline.serviceTypes,
					userid: this.GLOBAL.adminId(),
					checkFlag: this.listType === 'check' ? 1 : null,
					pageSize: this.pageSize,
					pageNum: this.currentPage
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//活动删除
			deleteRow(row) {
				checkBeforeDeleteAct({
					userid: this.GLOBAL.adminId(),
					uuid: row.uuid
				}).then(res => {
					if (res.result == 200) {
						let tip = '是否确认删除该活动信息？'
						if (res.detail.applyCount || res.detail.passCount) {
							tip = '该社员活动已有人报名，此操作会删除所有相关信息，是否确认删除？'
						}
						sureMessageBox(tip).then(res => {
							deleteAct({
								userid: this.GLOBAL.adminId(),
								uuid: row.uuid
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('操作成功')
									this.getList();
								} else {
									this.$message.warning(res.description)
								}
							})
						}).catch(err => {
							console.log(err)
						})

					} else {
						this.$message.warning(res.description)
					}
				})


			},

			//结束招募
			stopRow(row) {
				sureMessageBox('是否确认结束招募？').then(res => {
					stopRecruitAct({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//启动招募
			startRow(row) {
				sureMessageBox('是否确认启动招募？').then(res => {
					recruitAct({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//启动活动
			startActivity(row) {
				sureMessageBox('是否确认启动活动？').then(res => {
					volActStart({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//停止活动
			stopActivity(row) {
				sureMessageBox('是否确认停止活动？').then(res => {
					volActComplete({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//自定义序号
			indexMethod(index) {
				return (this.currentPage - 1) * this.pageSize + (index + 1);
			},
			//查询
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			addRow() {
				this.$refs.addRow.dialogFormVisible = true
			},
			//刷新活动主体列表
			updateRefreshList() {
				this.currentPage = 1;
				this.getList();
			},
			//提交审核
			submitCheck(row) {
				sureMessageBox('是否确认提交审核？').then(res => {
					submitAct({
						userid: this.GLOBAL.adminId(),
						uuid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功，请等待审核');
							this.getList()
						} else {
							this.$message.warning(res.description)
						}
					})
				})
			},
			//打开修改活动主体弹框、获取主体信息
			editActivity(row) {
				this.operationType = 'edit';
				this.updateActuuid = row.uuid;
				this.$refs.updateRow.dialogFormVisible = true
				this.$nextTick(() => {
					this.$refs.updateRow.getActDetail();
				})
			},
			//查看详情
			viewRow(row) {
				this.operationType = 'view';
				this.updateActuuid = row.uuid;
				this.$refs.updateRow.dialogFormVisible = true
				this.$nextTick(() => {
					this.$refs.updateRow.getActDetail();
				})
			},
			// 查看活动的报名社员列表
			lookVolList(row) {
				this.updateActuuid = row.uuid;
				this.currentActStatus = row.status;
				this.volListFlag = true;
			},
			checkActivity(row) {
				this.checkActuuid = row.uuid;
				this.checkDialogVisible = true;
			},
			// 查看活动的反馈列表
			lookFeedbackList(row) {
				// console.log(row)
				this.updateActuuid = row.uuid;
				this.currentServiceCategory = row.service_category;
				this.pricePoint = row.newpricepoint;
				this.serviceType = row.service_types;
				this.pointType = row.point_type;
				this.feedbackListFlag = true;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.searchbox {
		margin: 20px 10px;
	}


	.pagebox {
		margin: 20px 0;
		text-align: right;
	}

	.imgbox_table {
		height: 80px;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;
	}

	.memberinfobox {
		width: 96%;
		margin: auto;

		::v-deep .el-row {
			margin-bottom: 15px;
		}

		::v-deep .el-col {
			padding: 4px 0;
		}

		::v-deep .el-col-4 {
			background-color: #f5f5f5;
		}

		.checkinfobox {
			margin-top: 30px;

			.tit {
				font-size: 16px;
				color: #318fdf;
				line-height: 2em;
				border-bottom: 2px solid #318fdf;
			}
		}
	}


	.btnsbox {
		>div {
			margin: 5px 0;
		}
	}
</style>