<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true">
				<el-form-item label="人员姓名">
					<el-input v-model="formInline.name" clearable placeholder="请输入人员姓名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
					<el-button type="success" v-if="tableData.length>0" @click="exportXcle"><i
							class="el-icon-download"></i>excel导出</el-button>
					<el-button v-if="multipleSelection.length > 0" type="default" @click="auditMore"
						icon="el-icon-tickets">批量审核</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" stripe @selection-change="handleSelectionChange">
			<el-table-column :selectable="selectable" type="selection" width="55">
			</el-table-column>
			<el-table-column prop="volunteername" label="姓名"> </el-table-column>
			<el-table-column prop="contact_mobile" label="手机号"> </el-table-column>
			<el-table-column prop="skills" label="特长"> </el-table-column>
			<el-table-column prop="create_time" label="报名时间"> </el-table-column>
			<el-table-column label="报名状态">
				<template slot-scope="scope">
					<span>{{ checkStatusLabel(scope.row.audit_result) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="audit_opinion" label="报名审核意见"> </el-table-column>
			<el-table-column prop="auditor" label="审核人"> </el-table-column>
			<el-table-column label="操作" fixed="right" width="200">
				<template slot-scope="scope">
					<el-button @click="cancelApply(scope.row)" type="primary" size="mini"
						:disabled="scope.row.audit_result != 1">取消报名</el-button>
					<el-button @click="auditVolFn(scope.row)" type="primary" size="mini"
						:disabled="Boolean(scope.row.audit_result) || status !== 0">报名审核</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size.sync="fenye.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>

		<el-table v-show="false" :data="tableDataExport" stripe id="exportTable">
			<el-table-column prop="volunteername" label="姓名"> </el-table-column>
			<el-table-column prop="contact_mobile" label="手机号"> </el-table-column>
			<el-table-column prop="skills" label="特长"> </el-table-column>
			<el-table-column prop="create_time" label="报名时间"> </el-table-column>
			<el-table-column label="报名状态">
				<template slot-scope="scope">
					<span>{{ checkStatusLabel(scope.row.audit_result) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="audit_opinion" label="报名审核意见"> </el-table-column>
			<el-table-column prop="auditor" label="审核人"> </el-table-column>
		</el-table>
		<!-- 报名审核 -->
		<el-dialog append-to-body :close-on-click-modal="false" :visible.sync="auditVolDialog" title="报名审核"
			width="600px" :before-close="auditVolhandleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="130px"
				class="demo-ruleForm">
				<el-form-item label="审核状态" prop="isPass">
					<el-radio-group v-model="ruleForm.isPass" prop="isPass">
						<el-radio label="1">通过</el-radio>
						<el-radio label="2">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审核描述" prop="opinion">
					<el-input type="textarea" v-model="ruleForm.opinion"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary"
						@click="submitForm('ruleForm')">{{ auditMoreFlag ? '批量审核' : '审核'}}</el-button>
					<el-button @click="cancelFn">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getActVolList,
		actVolAudit,
		actVolAudits,
		cancelapply
	} from '@/api/showLove'
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	export default {
		props: {
			actuuid: {
				type: String
			},
			status: {
				type: Number
			},
		},
		data() {
			return {
				formInline: {
					name: ''
				},
				tableData: [],
				tableDataExport: [],
				auditStatus: [{
					value: null,
					label: '待审核' //已申请
				}, {
					value: 0,
					label: '待审核' //已申请
				}, {
					value: 1,
					label: '审核通过'
				}, {
					value: 2,
					label: '审核不通过'
				}],
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10,
				},
				auditVolDialog: false,
				voluuid: '',
				ruleForm: {
					isPass: null,
					opinion: '',
				},
				rules: {
					isPass: [{
						required: true,
						message: '请选择审核状态',
						trigger: 'change'
					}, ],
					opinion: [{
						required: true,
						message: '请输入审核描述',
						trigger: 'change'
					}, ],
				},
				multipleSelection: [],
				auditMoreFlag: false
			};
		},

		created() {
			this.getList();
		},

		computed: {},

		mounted() {},

		methods: {
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			getList() {
				getActVolList({
					pageSize: this.fenye.pagesize,
					pageNum: this.currentPage,
					// orderRule: 'person',
					orderRule: '',
					actuuid: this.actuuid,
					name: this.formInline.name
				}).then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = detail.list;
						//已审核的设置不可选择
						this.multipleSelection = [];
					}else{
						this.$message.error(res.description)
					}
				})
			},
			//批量选择
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log(this.multipleSelection)
			},
			//过滤已经审核过的数据，不给用户选择
			selectable: function(row, index) {
				if (Boolean(row.audit_result) || this.status !== 0) {
					return false
				} else {
					return true;
				}
			},
			checkStatusLabel(val) {
				let item = this.auditStatus.filter(item => item.value === val)
				return item[0].label;
			},
			//打开审核报名弹框
			auditVolFn(row) {
				this.voluuid = row.uuid;
				this.auditVolDialog = true;
				this.auditMoreFlag = false;
			},
			//批量审核
			auditMore() {
				this.auditVolDialog = true;
				this.auditMoreFlag = true;
				let auditVolUuids = []
				this.multipleSelection.map(item => {
					auditVolUuids.push(item.uuid)
				})
				this.voluuid = auditVolUuids.join(',');
			},
			//取消添加
			cancelFn(type) {
				this.$refs.ruleForm.resetFields();
				this.auditVolDialog = false;
				if (type == 'add') {
					this.currentPage = 1;
					this.getList();
				}
			},
			//重置表单
			resetForm() {
				this.$refs.ruleForm.resetFields();
			},
			//关闭审核积分弹框
			auditVolhandleClose(done) {
				this.resetForm();
				done()
			},
			cancelApply(row) {
				this.$confirm('是否确认取消该社员报名信息?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
						cancelapply({
							uuid: row.uuid,
							actUuid: this.actuuid,
						}).then(res => {
							if (res.result == 200) {
								this.$message.success('取消报名成功');
								this.currentPage = 1;
								this.getList();
							} else {
								this.$message.error(res.description)
							}
						})
				}).catch(() => {
		
				});
			},
			//确认添加
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否确认审核该社员报名信息?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

							if (this.auditMoreFlag) {
								//批量审核
								actVolAudits({
									uuids: this.voluuid,
									isPass: this.ruleForm.isPass,
									opinion: this.ruleForm.opinion,
									userId: this.GLOBAL.adminId()
								}).then(res => {
									if (res.result == 200) {
										this.$message.success('审核成功');
										this.cancelFn('add');
									} else {
										this.$message.error(res.description)
									}
								})
							} else {
								actVolAudit({
									uuid: this.voluuid,
									isPass: this.ruleForm.isPass,
									opinion: this.ruleForm.opinion,
									userId: this.GLOBAL.adminId()
								}).then(res => {
									if (res.result == 200) {
										this.$message.success('审核成功');
										this.cancelFn('add');
									} else {
										this.$message.error(res.description)
									}
								})
							}


						}).catch(() => {

						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			exportXcle() {
				getActVolList({
					pageSize: 999999,
					pageNum: this.currentPage,
					orderRule: '',
					actuuid: this.actuuid,
					name: ''
				}).then((res) => {
					if (res.result == 200) {
						let detail = res.detail;
						this.tableDataExport = detail.list;
						let downloadTimer = setTimeout(() => {
							clearTimeout(downloadTimer);
							let xlsxParam = {
								raw: true
							};
							let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"),
								xlsxParam);
							let wbout = XLSX.write(wb, {
								bookType: "xlsx",
								bookSST: true,
								type: "array",
							});
							try {
								FileSaver.saveAs(
									new Blob([wbout], {
										type: "application/octet-stream"
									}), "报名列表.xlsx"
								);
							} catch (e) {
								if (typeof console !== "undefined") console.log(e, wbout);
							}
							return wbout;
						}, 10)
					} else {
						this.$message.error(res.description);
					}
				});
			},
		}
	}
</script>
<style lang='scss' scoped>
	.paginationbox {
		text-align: right;
		margin-top: 20px;
	}
</style>