import request from '@/utils/request'

//--------询社员--------
//查询社员服务类型
export function getDictItem(data) {
  return request({
    url: '/sysdict/getDictItem',
    method: 'get',
    params: data
  })
}
// 查询社员服务类型
export function getVolServiceType(data) {
  return request({
    url: '/volServiceType/list',
    method: 'get',
    params: data
  })
}
// 根据id查询社员服务类型
export function getVolServiceTypeById(data) {
  return request({
    url: '/volServiceType/get',
    method: 'get',
    params: data
  })
}
// 新增社员服务类型
export function createVolServiceType(data) {
  return request({
    url: '/volServiceType/create',
    method: 'post',
    data: data
  })
}
// 删除社员服务类型
export function deleteVolServiceType(data) {
  return request({
    url: '/volServiceType/delete',
    method: 'get',
    params: data
  })
}

//社员列表
export function memberList(params, data) {
  return request({
    url: '/MA_volunteer/page',
    method: 'post',
    params: params,
    data: data
  })
}

//社员二维码
export function codePic(params, data) {
  return request({
    url: '/MA_volunteer/getQrCode',
    method: 'post',
    params: params,
    data: data
  })
}

//导出社员列表
export function exportMemberList(data) {
  return request({
    url: '/MA_volunteer/export',
    method: 'post',
    params: data,
    responseType: "blob",
  })
}

//社员删除
export function deleteMember(data) {
  return request({
    url: '/MA_volunteer/delete',
    method: 'post',
    params: data
  })
}

//社员资料审核
export function auditMember(data) {
  return request({
    url: '/MA_volunteer/audit',
    method: 'post',
    params: data
  })
}

//批量社员资料审核
export function auditMembers(data) {
  return request({
    url: '/MA_volunteer/batchAudit',
    method: 'post',
    params: data
  })
}

//社员暂停
export function pauseMember(data) {
  return request({
    url: '/MA_volunteer/pause',
    method: 'post',
    params: data
  })
}

//社员重新启用
export function reEnableMember(data) {
  return request({
    url: '/MA_volunteer/reEnable',
    method: 'post',
    params: data
  })
}

//社员新增
// export function addMember(data) {
//   return request({
//     url: '/MA_volunteer/apply',
//     method: 'post',
//     params: data
//   })
// }
export function addMember(data) {
  return request({
    url: '/MA_volunteer/add',
    method: 'post',
    data: data
  })
}
export function updateMember(data) {
  return request({
    url: '/MA_volunteer/update',
    method: 'post',
    data: data
  })
}

//社员详情
export function memberDetail(data) {
  return request({
    url: '/MA_volunteer/detail',
    method: 'post',
    params: data
  })
}




//------------社员活动接口----------
//新增志愿活动
export function addAct(data) {
  return request({
    url: '/MA_volActivity/add',
    method: 'post',
    data: data
  })
}

//修改志愿活动
export function updateAct(data) {
  return request({
    url: '/MA_volActivity/update',
    method: 'post',
    data: data
  })
}

//分页查询社员活动
export function queryPageAct(data) {
  return request({
    url: '/MA_volActivity/queryPage',
    method: 'post',
    params: data
  })
}

//社员活动数据删除
export function deleteAct(data) {
  return request({
    url: '/MA_volActivity/delete',
    method: 'post',
    params: data
  })
}

//活动提交审核
export function submitAct(data) {
  return request({
    url: '/MA_volActivity/submit',
    method: 'post',
    params: data
  })
}

//活动审核
export function auditAct(data) {
  return request({
    url: '/MA_volActivity/audit',
    method: 'post',
    params: data
  })
}

//社员活动删除之前校验
export function checkBeforeDeleteAct(data) {
  return request({
    url: '/MA_volActivity/checkBeforeDelete',
    method: 'post',
    params: data
  })
}

//活动上架OR下架
export function upDownAct(data) {
  return request({
    url: '/MA_volActivity/upDown',
    method: 'post',
    params: data
  })
}

//活动主体信息查询
export function detailAct(data) {
  return request({
    url: '/MA_volActivity/detail',
    method: 'post',
    params: data
  })
}

//活动置顶或者取消
export function stickAct(data) {
  return request({
    url: '/MA_volActivity/stick',
    method: 'post',
    params: data
  })
}

//活动启用招募
export function recruitAct(data) {
  return request({
    url: '/MA_volActivity/recruit',
    method: 'post',
    params: data
  })
}


//活动停止招募
export function stopRecruitAct(data) {
  return request({
    url: '/MA_volActivity/stopRecruit',
    method: 'post',
    params: data
  })
}


//启动活动
export function volActStart(data) {
  return request({
    url: '/MA_volActivity/volActStart',
    method: 'post',
    params: data
  })
}



//暂停活动
export function volActComplete(data) {
  return request({
    url: '/MA_volActivity/volActComplete',
    method: 'post',
    params: data
  })
}

// 社员活动 报名列表
export function getActVolList(data) {
  return request({
    url: '/MA_volApply/page',
    method: 'post',
    params: data
  })
}

// 社员活动 报名人员审核
export function actVolAudit(data) {
  return request({
    url: '/MA_volApply/audit',
    method: 'post',
    params: data
  })
}
//社员活动 报名人员批量审核
export function actVolAudits(data) {
  return request({
    url: '/MA_volApply/batchAudit',
    method: 'post',
    params: data
  })
}

// 社员活动 报名列表
export function getFeedbackList(data) {
  return request({
    url: '/MA_volFeedback/page',
    method: 'post',
    params: data
  })
}

// 社员活动 反馈审核
export function actVolFeedbackAudit(data) {
  return request({
    url: '/MA_volFeedback/feedbackAudit',
    method: 'post',
    params: data
  })
}

// 社员活动 反馈审核全部
export function actVolFeedbackAuditAll(data) {
  return request({
    url: '/MA_volFeedback/feedbackAuditAll',
    method: 'post',
    params: data
  })
}

// 党支部信息
export function queryOrganization(data) {
  return request({
    url: '/volCpcOrg/queryOrganization',
    method: 'get',
    params: data
  })
}
//反馈列表导出
export function exportList(data) {
  return request({
    url: '/MA_volFeedback/exportList',
    method: 'get',
    params: data,
	responseType: "blob",
  })
}

//导入
export function importData(form, data) {
  return request({
    url: '/MA_volunteer/importData',
    method: 'post',
    params: data,
		data: form
  })
}

// 社员反馈次数
export function findAuditRecords(data) {
  return request({
    url: '/MA_volFeedback/findAuditRecords',
    method: 'post',
    params: data
  })
}

// 社员活动取消报名
export function cancelapply(data) {
  return request({
    url: '/MA_volActivity/cancelapply',
    method: 'post',
    params: data
  })
}

//生成任务二维码
export function qrcodeProduct(data) {
  return request({
    url: '/MA_volActivity/createVolCode',
    method: 'post',
    params: data
  })
}
