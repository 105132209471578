<template>
  <div>
    <!-- checkFlag 0 未提交，1是待审核 -->
    <activity-list :listType="listType"></activity-list>
  </div>
</template>

<script>
import activityList from './index'
export default {
  name:"",
  components:{
    activityList
  },
  props:{},
  data(){
    return {
      listType: 'check'
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{}
}
</script>

<style lang="scss" scoped>
  
</style>